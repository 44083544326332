import React from 'react';
import { Helmet } from 'react-helmet';

function NotFound() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amwell Private Practice | Page Not Found</title>
      </Helmet>
      <div>Page Not Found</div>
    </>
  );
}

export default NotFound;
